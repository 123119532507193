$navi =$('.navi')
$naviItem =$('.navi__item')
$naviPoint = $('.naviPoint')
naviPointPos = {}

$naviPoint.each(
  (index, val)->
    if $(this).attr('id')
      position = {}
      position[$(this).attr('id')] = $(this).offset().top
      naviPointPos[Object.keys(naviPointPos).length] = position
)

$(window).on('load',
  ()->
    # gsap.registerPlugin(ScrollTrigger)

    # area = document.querySelector('.p-feature__scroll')
    # wrap = document.querySelector('.p-feature__list')
    # items = document.querySelectorAll('.p-feature__item')
    # num = items.length

    # gsap.set(wrap,  { width: num * 100 + '%' })
    # gsap.set(items, { width: 1080+ 'px' })

    # gsap.to(items, {
    #   xPercent: -100 * ( num - 1 ),
    #   ease: 'none',
    #   scrollTrigger: {
    #     trigger: area,
    #     start: 'top top',
    #     # end: '+=1000',
    #     end: 'bottom top',
    #     pin: true,
    #     scrub: true,
    #   }
    # })
)

$(window).on('scroll',
  ()->
    scroll = $(window).scrollTop()
    wHeight = $(window).height()
    scrollPos = scroll + wHeight
    headerVal = $('#l-header').outerHeight(true)

    if window.matchMedia('(max-width:750px)').matches
      $.each(naviPointPos,
        (key, val)->
          target = Object.keys(val)
          $target = $('#'+target)

          if $(window).scrollTop() >= $target.position().top - 400
            $target.addClass('is-active')

          if $(window).scrollTop() >= $target.position().top - 100
            $navi.find('.'+target).addClass('is-active')
            $naviItem.not('.'+target).removeClass('is-active')
            $target.addClass('is-active')

          if $(window).scrollTop() < $($naviPoint.get(0)).position().top
            $naviItem.removeClass('is-active')
      )

      if scroll > $('.p-feature').offset().top - 500
        if $('.p-target').hasClass('is-active')
          setTimeout(
            ()->
              $('.p-target .p-target__pic1').addClass('is-active')
            , (0))
          setTimeout(
            ()->
              $('.p-target .p-target__pic2').addClass('is-active')
            , (500))
          setTimeout(
            ()->
              $('.p-target .p-target__pic3').addClass('is-active')
            , (1000))

      if scroll > $('.p-price').offset().top - headerVal
        if scroll > $('.p-price .priceBox1').offset().top - 200
          setTimeout(
            ()->
              $('.p-price .subbox2__box1__item').addClass('is-active')
            , (0))
          setTimeout(
            ()->
              $('.p-price .subbox2__box2').addClass('is-active')
              $('.p-price .priceBox1__subbox.subbox3').addClass('is-active')
            , (2000))
        if scroll > $('.p-price .priceBox3').offset().top - 200
          setTimeout(
            ()->
              $('.p-price .priceBox3__pic2__item').addClass('is-active')
            , (0))

      if scroll > $('.p-flow').offset().top - headerVal - 300
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__pic1').addClass('is-active')
          , (0))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item1').addClass('is-active')
          , (2000))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item2').addClass('is-active')
          , (2800))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item3').addClass('is-active')
          , (3800))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item4').addClass('is-active')
          , (6200))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__pic1').addClass('is-active')
          , (6200))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item1').addClass('is-active')
          , (7500))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item2').addClass('is-active')
          , (8500))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item3').addClass('is-active')
          , (9500))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item4').addClass('is-active')
          , (10700))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__pic2').addClass('is-active')
          , (9000))
    else
      $.each(naviPointPos,
        (key, val)->
          target = Object.keys(val)
          $target = $('#'+target)

          if $(window).scrollTop() >= $target.position().top - 400
            $target.addClass('is-active')

          if $(window).scrollTop() >= $target.position().top - $navi.height() and
          $(window).scrollTop() < $target.position().top + $target.outerHeight(true)
            $navi.find('.'+target).addClass('is-active')
            $naviItem.not('.'+target).removeClass('is-active')

          if $(window).scrollTop() < $($naviPoint.get(0)).position().top - $navi.height()
            $naviItem.removeClass('is-active')
      )

      if scroll > $('.p-feature').offset().top - 700
        if $('.p-target').hasClass('is-active')
          setTimeout(
            ()->
              $('.p-target .p-target__pic1').addClass('is-active')
            , (1000))
          setTimeout(
            ()->
              $('.p-target .p-target__pic2').addClass('is-active')
            , (1500))
          setTimeout(
            ()->
              $('.p-target .p-target__pic3').addClass('is-active')
            , (2000))

      if scroll > $('.p-price').offset().top - headerVal
        if scroll > $('.p-price .priceBox1').offset().top - 200
          setTimeout(
            ()->
              $('.p-price .subbox2__box1__item').addClass('is-active')
            , (0))
          setTimeout(
            ()->
              $('.p-price .subbox2__box2').addClass('is-active')
              $('.p-price .priceBox1__subbox.subbox3').addClass('is-active')
            , (2000))
        if scroll > $('.p-price .priceBox3').offset().top - 200
          setTimeout(
            ()->
              $('.p-price .priceBox3__pic2__item').addClass('is-active')
            , (0))

      if scroll > $('.p-flow').offset().top - headerVal - 400
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__pic1').addClass('is-active')
          , (0))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item1').addClass('is-active')
          , (2000))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item2').addClass('is-active')
          , (2800))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item3').addClass('is-active')
          , (3800))
        setTimeout(
          ()->
            $('.p-flow .p-flow__mainbox__list .item4').addClass('is-active')
          , (5200))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__pic1').addClass('is-active')
          , (6200))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item1').addClass('is-active')
          , (7500))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item2').addClass('is-active')
          , (8500))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item3').addClass('is-active')
          , (9500))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__list .item4').addClass('is-active')
          , (10700))
        setTimeout(
          ()->
            $('.p-flow .p-flow__subbox__pic2').addClass('is-active')
          , (8500))

      # if $('.p-faq').offset().top - 100 < scroll
      #   move1 = Math.round(scroll * 0.1)

        # $('.p-faq .p-faq__pic').css({
        #   'transform': 'matrix(1, 0, 0, 1, 0, -'+move1+')'
        # })
)

# $('.p-about__list').on('scroll',
#   ()->
#     if ($('.p-about__list').offset().top + $('.p-about__pic1').height() - 200) < $('.p-about__pic2').offset().top
#       $('.p-about__pic3 .pic3_2').addClass('is-active')
#       $('.p-about__pic3 .pic3_3').removeClass('is-active')
#     else
#       $('.p-about__pic3 .pic3_2').removeClass('is-active')
#       $('.p-about__pic3 .pic3_3').addClass('is-active')
# )

if window.matchMedia('(max-width:750px)').matches
else
  rellax = new Rellax('.rellaxFeaturePic', {
    center: true,
  })

  rellax = new Rellax('.rellaxQa', {
    center: true,
  })


ScrollReveal().reveal('.p-mainvisual .boxPic2', {
  delay: 2500,
  duration: 2400,
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-mainvisual .boxPic3', {
  delay: 2800,
  duration: 2400,
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-mainvisual .boxPic1', {
  delay: 3200,
  duration: 2400,
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-mainvisual .itemPic1', {
  delay: 3500,
  duration: 2600,
  origin: 'top',
  distance: '60px',
  reset: false
})

ScrollReveal().reveal('.p-mainvisual .itemPic2', {
  delay: 3800,
  duration: 2600,
  origin: 'top',
  distance: '60px',
  reset: false
})

ScrollReveal().reveal('.p-mainvisual .itemPic3', {
  delay: 4100,
  duration: 2600,
  origin: 'top',
  distance: '60px',
  reset: false
})

ScrollReveal().reveal('.p-mainvisual .p-mainvisual__lead', {
  delay: 4400,
  duration: 2400,
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-price .priceBox2__pic3__item2', {
  delay: 2400,
  duration: 3000,
  origin: 'top',
  distance: '60px',
  reset: false
})

ScrollReveal().reveal('.p-price .priceBox2__pic3__item3', {
  delay: 2400,
  duration: 3000,
  origin: 'top',
  distance: '60px',
  reset: false
})
